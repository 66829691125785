import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Navbar from "../components/nav-bar"

const GoogleAuthenticator = () => {


  return (
    <html class="dark bg-darkbackg">
      <SEO title="Google Authenticator" description='Import 2FA from Google Authenticator to Obsidian' />
      <div class="dark:bg-darkbackg h-full flex flex-col items-start justify-center">

        <Navbar />

        <div class="h-full pt-10 mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center overflow-auto">

            <p class="text-3xl font-extrabold tracking-tight text-white font-sans sm:text-3xl flex-row justify-center flex">
              Coming from Google Authenticator?
            </p>

            <div class="dark:bg-darkbackg w-full mx-auto sm:px-6 md:px-6 flex flex-row justify-center">
              <img style={{ height: 120, margin: 10 }} class="" src={require('../images/googleauthenticator.png')} />
            </div>

            <dd class="mt-3 text-base text-gray-300 font-sans md:w-8/12 text-center">
              You can now <b>migrate all your tokens from Google Authenticator automatically</b>. <br />
              <b style={{ color: '#658ac9' }}>1.</b> Open Google authenticator <br />
              <b style={{ color: '#658ac9' }}>2.</b> Tap "•••" <br />
              <b style={{ color: '#658ac9' }}>3.</b> Tap "Export accounts" <br />
              <b style={{ color: '#658ac9' }}>4.</b> Tap "Continue" <br />
              <br />
              <b style={{ fontWeight: '800' }}>If you have Obsidian on another device:</b> <br />
              <b style={{ color: '#658ac9' }}>5a.</b> Open Obsidian and scan the QR Code generated by Google Authenticator <br />
              <br />
              <b style={{ fontWeight: '800' }}>If you have Obsidian on same device:</b> <br />
              <b style={{ color: '#658ac9' }}>5b.</b> Screenshot the QR Code generated by Google Authenticator <br />
              <b style={{ color: '#658ac9' }}>6b.</b> Open Obsidian, Tap "+" and "Upload QR Code". Select the QR Code previously saved. <br />
              <b style={{ color: '#658ac9' }}>7b.</b> (optional) Delete the QR Code from photo library
            </dd>
          </div>
        </div>

    </html>
  )
}

export default GoogleAuthenticator
